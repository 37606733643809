/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;

@import "tailwindcss/base";

/**
 * Custom base styles, applied after the tailwind-base classes
 */

html {
  font-size: 62.5%;
  font-family: 'Inter';
  background-color: #121212;
}

body {
  font-size: 13px;
  line-height: 1.4;
  overflow-x: hidden;
  font-family: 'Inter';
}

html,
body,
#root {
  position: relative;
  margin: 0;
  min-height: 100vh;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

[role="tooltip"] {
  z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
  transition: none !important;
  animation: none !important;
}

button:focus {
  outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transitiondelay: 9999s;
  transitionproperty: background-color, color;
}

:focus {
  outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.H1_28 {
  font-family: 'Mail Sans Roman DemBd';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: -0.02em;
}

.H2_22 {
  font-family: 'Mail Sans Roman DemBd';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}

.H3_18 {
  font-family: 'Mail Sans Roman DemBd';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}

.H4_16 {
  font-family: 'Mail Sans Roman DemBd';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.H5_12 {
  font-family: 'Mail Sans Roman DemBd';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.0025em;
}

.Normal_15R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.0025em;
}

.Small_13R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: 0.0025em;
}

.Normal_16R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
}

.Medium_14R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  letter-spacing: 0.0025em;
}

.Small_12R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  /* or 19px */

  letter-spacing: 0.0025em;
}

.Medium_14M {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.0025em;
}

.Table_header_12 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: uppercase;
}

.Normal_15{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
}

.Small_13 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-transform: uppercase;
}