.apexcharts-legend-series {
    display: flex !important;
    flex-direction: row;
  }
  .apexcharts-legend-text {
    width: 100%;
  }
  .graphLabelName {
    margin-right: 20px;
    margin-left: 10px;
    max-width: 80%;
  }
  .graphLabel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }